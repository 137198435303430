// TODO-BOOS-1010 – Uncomment the commented code after the release
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { useAppSelector } from 'store/hooks'
import { getLocationDataSelector, sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import {
  getUserFirstPurchaseSelector,
  getUserRegisteredCountrySelector,
  isLoggedInSelector,
  getLiveChatPurchaseFlowEnabledSelector,
} from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'
import { isPlayPage } from 'utils/route'

type ShowChatsData = {
  isShowLiveChat: boolean
  isShowLiveChatFromMenu: boolean
  isShowChatBot: boolean
  isShowChatBotCom: boolean
}

const ALLOWED_COUNTRY = 'US'
const RESTRICTED_ROUTES = [ROUTES.IDENTITY_VERIFICATION]

export const SNIPPET_KEY = process.env.ZENDESK_SNIPPET_KEY
export const APP_ID = process.env.ZENDESK_APP_ID
export const BLOCKED_STATES = process.env.ZENDESK_BLOCKED_STATES
export const CHAT_BOT_ID = process.env.CHAT_BOT_ID

export default function useShowChats(): ShowChatsData {
  const pathname = usePathname()
  const loggedIn = useAppSelector(isLoggedInSelector)
  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)
  const location = useAppSelector(getLocationDataSelector)
  const firstPurchase = useAppSelector(getUserFirstPurchaseSelector)
  const liveChatPurchaseFlow = useAppSelector(getLiveChatPurchaseFlowEnabledSelector)
  const country = useAppSelector(getUserRegisteredCountrySelector)

  const isGamePage = isPlayPage(pathname)
  const isLoginAndCountryAllowed = loggedIn && country === ALLOWED_COUNTRY
  const blockedStates = typeof BLOCKED_STATES === 'string' ? BLOCKED_STATES.split(',') : (BLOCKED_STATES as string[])

  const isRouteRestricted = useMemo((): boolean => RESTRICTED_ROUTES.includes(pathname), [pathname])

  // [FYI]: [firstPurchase === undefined] need for waiting response from GetPaymentMetaInfoResponse
  if (isGamePage || !isLoginAndCountryAllowed || firstPurchase === undefined || isRouteRestricted) {
    return {
      isShowLiveChat: false,
      isShowLiveChatFromMenu: false,
      isShowChatBot: false,
      isShowChatBotCom: false,
    }
  }

  const isExistZendeskKeys = Boolean(APP_ID && SNIPPET_KEY)
  const isShowChatBots = !isScEnabled && !blockedStates?.find((el) => el === location?.state)
  const isLiveChatEnabled = isLoginAndCountryAllowed && isScEnabled && isExistZendeskKeys // [FYI]: general logic for Live chat

  return {
    isShowLiveChat: isLiveChatEnabled && liveChatPurchaseFlow,
    isShowLiveChatFromMenu: isLiveChatEnabled && !!firstPurchase, // [FYI]: logic for Live chat from menu link
    isShowChatBot: isShowChatBots && isExistZendeskKeys,
    isShowChatBotCom: isShowChatBots && Boolean(CHAT_BOT_ID),
  }
}

import type { TempTokenRequestOptions } from '@patrianna/shared-patrianna-types/services/tokens'
import type { GetTemporaryTokenRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetTemporaryTokenResponse } from '@patrianna/shared-patrianna-types/websocket/response'

import { serviceDependencies } from 'services'

export const getTemporaryToken = async (options: TempTokenRequestOptions) => {
  const data: GetTemporaryTokenRequest = {
    type: 'GetTemporaryTokenRequest',
    ...options,
  }
  try {
    return await serviceDependencies.gateway.emit<GetTemporaryTokenResponse>(data)
  } catch (err) {
    return { err, reqData: data }
  }
}

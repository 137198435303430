import { useOtpData } from 'hooks/mf/useOtpData'

export default function useOtpConfirmRequired() {
  const { mandatoryAndClosed = true, skip } = useOtpData()

  if (skip) {
    return false
  }

  return !(!skip && mandatoryAndClosed)
}

import type { PWAEventType } from '@patrianna/shared-patrianna-types/store/AppConfigModule'
import { useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { togglePWAMessage } from 'store/modules/appConfig/actions'
import { HIDE_INSTALL_PWA_BUTTON, getItemFormLocalStorageSelector, setDataToLocalStorage } from 'utils/localStorage'

let GLOBAL_PWA_INSTANCE: PWAEventType | { prompt: () => void } | null = null

const usePWAInstance = (): { prompt: () => void } | PWAEventType | null => {
  const [pwaInstance, setPWAInstance] = useState(GLOBAL_PWA_INSTANCE)
  const [isPWAInstalled, setIsPWAInstalled] = useState(false)
  const isPWAIOSButtonHidden = useAppSelector((state) =>
    getItemFormLocalStorageSelector(state, HIDE_INSTALL_PWA_BUTTON, false)
  )
  const dispatch = useAppDispatch()

  const detectPWAInstalled = () => {
    setIsPWAInstalled(true)
    setPWAInstance(null)
  }
  const beforeinstallpromptHandler = (e: PWAEventType) => {
    e.preventDefault()
    GLOBAL_PWA_INSTANCE = e
    setPWAInstance(GLOBAL_PWA_INSTANCE)

    e.userChoice.then((res) => {
      if (res?.outcome === 'accepted') {
        detectPWAInstalled()
      }
    })
  }

  useEffect(() => {
    if (!isPWAIOSButtonHidden && !window.matchMedia('(display-mode: standalone)').matches) {
      setPWAInstance({
        prompt: () => {
          dispatch(togglePWAMessage({ isPWAMessageOpen: true }))
          dispatch(setDataToLocalStorage(HIDE_INSTALL_PWA_BUTTON, true, false))
          setPWAInstance(null)
        },
      })
    }

    window.addEventListener('beforeinstallprompt', beforeinstallpromptHandler)
    window.addEventListener('appinstalled', detectPWAInstalled)

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeinstallpromptHandler)
      window.removeEventListener('appinstalled', detectPWAInstalled)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [])

  if (pwaInstance && !isPWAInstalled) {
    return pwaInstance
  }

  return null
}

export const useIsPWAAvailable = () => {
  const [isAvailable, setIsAvailable] = useState(false)

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsAvailable(false)
    } else {
      setIsAvailable(true)
    }
  }, [])

  return isAvailable
}

export default usePWAInstance
